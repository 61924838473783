/* ========================================================================
 * Apricot's Dropdown
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/dropdown.scss';


// javaScript
import Utils from './CBUtils'

/**
 * Dropdown 
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Boolean} data.filter
 * @param {Boolean} data.adjustHeight
 * @param {Boolean} data.caseSensitive
 * @param {Boolean} data.closeOnClickOutside
 * @returns {{destroy: Function}}
 * 
 */
const Dropdown = (data = {}) => {
  const defaultData = {
    elem: null,
    filter: false,
    adjustHeight: true,
    caseSensitive: false,
    closeOnClickOutside: true,
  }

  data = {
    ...defaultData,
    ...data
  }

  let elem = data.elem
  let toggle = null
  let menu = null
  let openIcon = null
  let closeIcon = null
  let filter = null

  if (!Utils.elemExists(elem)) return null

  const init = () => {
    elem.dropdown = 'cb'

    toggle = elem.querySelector('.cb-dropdown-toggle')
    menu = elem.querySelector('.cb-dropdown-menu')

    if (!Utils.elemExists(menu)) return null

    if (data.filter) {
      filter = elem.querySelector('input')
    }

    const icons = toggle.querySelectorAll('.cb-glyph')
    if (icons[0]) {
      openIcon = icons[0]
      if (icons[1]) {
        closeIcon = icons[1]
      }
    }

    toggle.addEventListener('click', toggleDropdown)
    toggle.addEventListener('keyup', openDropdown)

    A11yEvents()
    if (data.closeOnClickOutside) {
      closeOnClickOutside()
    }

    if (Utils.elemExists(filter)) {
      filter.addEventListener('keyup', filterDropdown)
      filter.addEventListener('change', filterDropdown)
    }
  }

  const filterDropdown = (e) => {
    const value = cleanValue(filter.value);

    Array.prototype.forEach.call(menu.querySelectorAll('a'), (node) => {
      const txtValue = node.textContent || node.innerText;
      const parent = Utils.parent(node)

      if (cleanValue(txtValue).indexOf(value) > -1) {
        Utils.removeClass(parent, 'cb-hidden')

        Utils.attr(node, 'tabIndex', '0')
        Utils.removeAttr(node, 'aria-hidden')
      } else {
        Utils.addClass(parent, 'cb-hidden')

        Utils.attr(node, 'tabIndex', '-1')
        Utils.attr(node, 'aria-hidden', 'true')
      }
    })
  }

  const cleanValue = (value) => {
    let str = value
    if (!data.caseSensitive) {
      str = value.toUpperCase()
    }

    return str
  }

  const openDropdown = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.keyCode === 32 || e.keyCode === 40) {

      if (!Utils.hasClass(elem, 'cb-open')) {
        toggleDropdown(e)
      }
    }
  }

  const toggleDropdown = (e) => {
    if (e) e.preventDefault()

    // is open -> close
    if (Utils.hasClass(elem, 'cb-open')) {
      Utils.removeClass(elem, 'cb-open')

      Utils.removeClass(openIcon, 'cb-hidden')
      Utils.addClass(closeIcon, 'cb-hidden')

      Utils.attr(toggle, 'aria-expanded', 'false')
      resetHeightAdjustment()

      elem.querySelectorAll('a')[0].focus()

      const event1 = new CustomEvent('apricot_dropdownClose')
      elem.dispatchEvent(event1)
    } else {
      Utils.addClass(elem, 'cb-open')

      Utils.removeClass(closeIcon, 'cb-hidden')
      Utils.addClass(openIcon, 'cb-hidden')

      Utils.attr(toggle, 'aria-expanded', 'true')

      const nodes = getFocusableNodes()
      adjustDropdownHeight()
      adjustPosition()

      if (nodes.length > 0) {
        setTimeout(() => {
          nodes[0].focus()
        }, 50);
      }

      const event2 = new CustomEvent('apricot_dropdownOpen')
      elem.dispatchEvent(event2)
    }
  }

  const adjustPosition = () => {
    const left = menu.getBoundingClientRect().left
    const mWidth = menu.offsetWidth
    const wWidth = Utils.windowsDimension().width
    const limit = parseInt(left + mWidth)

    if (limit > wWidth) {
      Utils.addClass(menu, 'cb-menu-right')
    } else {
      Utils.removeClass(menu, 'cb-menu-right')
    }
  }

  const adjustDropdownHeight = () => {
    const top = menu.getBoundingClientRect().top
    const mHeight = menu.offsetHeight
    const wHeight = Utils.windowsDimension().height

    const limit = parseInt(wHeight - top)

    if (mHeight > limit) {
      Utils.addClass(menu, 'cb-dropdown-menu-scroll')

      menu.style.height = parseInt(limit - 48) + 'px'
    } else {
      resetHeightAdjustment()
    }
  }

  const resetHeightAdjustment = () => {
    menu.style.height = 'auto'
    Utils.removeClass(menu, 'cb-dropdown-menu-scroll')
  }

  const getFocusableNodes = () => {
    const list = []

    menu.querySelectorAll(Utils.FOCUSABLE_ELEMENTS).forEach((node) => {
      if (Utils.attr(node, 'aria-disabled') != 'true' && Utils.attr(node, 'aria-hidden') != 'true' && Utils.attr(node, 'tabindex') != '-1') {
        list.push(node)
      }
    })

    return list
  }

  const closeOnClickOutside = () => {
    document.addEventListener('keydown', closeA11Y, true);
    document.addEventListener('click', closeA11Y, true);
  }

  const closeA11Y = (e) => {
    if (e.type === 'click') {
      if (!Utils.hasClass(elem, 'cb-open') || elem.contains(e.target)) {
        return;
      }
      toggleDropdown()
    } else if (e.keyCode === 27) {
      if (!Utils.hasClass(elem, 'cb-open')) {
        return;
      }
      toggleDropdown()
    }
  }

  const A11yEvents = () => {
    menu.querySelectorAll('a').forEach((node) => {
      if (Utils.attr(node, 'aria-disabled') == 'true' || Utils.attr(node, 'aria-hidden') == 'true') {

        node.addEventListener('click', (e) => {
          e.preventDefault()
        })
      }
    })

    keyBoardNavigation()
  }

  const keyBoardNavigation = () => {
    Array.prototype.forEach.call(getFocusableNodes(), (node) => {
      node.addEventListener('keydown', keydownEvent)
    })
  }

  const keydownEvent = (e) => {
    const node = e.currentTarget
    const k = e.which || e.keyCode

    if (k !== 9 && k !== 40 && k !== 38 && k !== 16) {

      return;
    }

    let index = 0
    const tabbingBack = e.shiftKey;
    const items = getFocusableNodes()

    Array.prototype.forEach.call(items, function (item, i) {
      if (node === item) {
        index = i
      }
    })

    //make sure menus are closed after tab away
    if ((k === 9) && (((k === 9 && tabbingBack) && index === 0) || (!tabbingBack && index === items.length - 1))) {
      if ((k === 9 && tabbingBack) && index === 0) {
        e.preventDefault()
        e.stopPropagation()
      }

      const event = new CustomEvent('apricot_dropdownKeyboardToggle')
      let obj = {}
      obj.tab = (k === 9)
      obj.shiftTab = tabbingBack

      event.data = obj
      elem.dispatchEvent(event)

      toggleDropdown()
    } else { //up/down arrows
      e.preventDefault()
      e.stopPropagation()

      if (k === 38 || (k === 9 && tabbingBack)) {
        index--; //up|shift+tab
      } else if (k === 40 || k === 9) {
        index++; //down|tab
      }

      if (index < 0 || index === items.length) {
        return
      }

      const newActive = items[index]
      newActive.focus()
    }
  }

  const keyBoardReset = () => {
    Array.prototype.forEach.call(getFocusableNodes(), (node) => {
      node.removeEventListener('keydown', keydownEvent)
    })

    Array.prototype.forEach.call(getFocusableNodes(), (node) => {
      node.addEventListener('keydown', keydownEvent)
    })
  }

  const destroy = () => {
    if (elem.dropdown === 'cb') {
      elem.dropdown = null
      if (data.closeOnClickOutside) {
        document.removeEventListener('keydown', closeA11Y, true);
        document.removeEventListener('click', closeA11Y, true);
      }
      if (filter) {
        filter.removeEventListener('onkeyup', filterDropdown)
      }
    }
  }

  if (elem.dropdown !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
    keyBoardReset: keyBoardReset
  }
}

export default Dropdown