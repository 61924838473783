/* ========================================================================
 * Apricot's Popover
 * ========================================================================
 *
 * This plugin is depended on
 * https://github.com/FezVrasta/popper.js
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import "../scss/includes/popover.scss";

// javaScript
import Popper from 'popper.js';
import Utils from './CBUtils'


/**
 * Title function, its context is the Popover instance.
 * @memberof Popover
 * @callback TitleFunction
 * @return {String} placement - The desired title.
 */

const DEFAULT_OPTIONS = {
  container: false,
  delay: { show: 200, hide: 100 },
  html: false,
  placement: 'top',
  title: '',
  template:
    '<div class="cb-popover" role="popover"><div class="cb-popover-inner"><div class="cb-popover-header"></div><div class="cb-popover-content"></div></div></div>',
  trigger: 'click',
  offset: 0,
  innerSelector: '.cb-popover-inner',
  headerSelector: '.cb-popover-header',
  headerTag: 'h3',
  contentSelector: '.cb-popover-content',
  closeOnClickOutside: true
};

export default class Popover {
  /**
   * Create a new Popover.js instance
   * @class Popover
   * @param {HTMLElement} reference - The DOM node used as reference of the popover (it can be a jQuery element).
   * @param {Object} options
   * @param {String} options.placement='top'
   *      Placement of the popper accepted values: `top(-start, -end), right(-start, -end), bottom(-start, -end),
   *      left(-start, -end)`
   * @param {String} [options.innerSelector='.cb-popover-inner] - className used to locate the DOM inner element in the popover.
   * @param {HTMLElement|String|false} options.container=false - Append the popover to a specific element.
   * @param {Number|Object} options.delay=0
   *      Delay showing and hiding the popover (ms) - does not apply to manual trigger type.
   *      If a number is supplied, delay is applied to both hide/show.
   *      Object structure is: `{ show: 500, hide: 100 }`
   * @param {Boolean} options.html=false - Insert HTML into the popover. If false, the content will inserted with `textContent`.
   * @param {String} [options.template='<div class="cb-popover" role="popover"><div class="cb-popover-inner"><div class="cb-popover-header"></div><div class="cb-popover-content"></div></div></div>']
   *      Base HTML to used when creating the popover.
   *      The popover's `title` will be injected into the `.cb-popover-inner` or `.cb-popover__inner`.
   *      The outermost wrapper element should have the `.cb-popover` class.
   * @param {String|HTMLElement|TitleFunction} options.title='' - Default title value if `title` attribute isn't present.
   * @param {String} [options.trigger='click']
   *      How popover is triggered - click, manual.
   *      You may pass multiple triggers; separate them with a space. `manual` cannot be combined with any other trigger.
   * @param {Boolean} options.closeOnClickOutside=false - Close a popper on click outside of the popper and reference element. This has effect only when options.trigger is 'click'.
   * @param {String|HTMLElement} options.boundariesElement
   *      The element used as boundaries for the popover. For more information refer to Popper.js'
   *      [boundariesElement docs](https://popper.js.org/popper-documentation.html)
   * @param {Number|String} options.offset=0 - Offset of the popover relative to its reference. For more information refer to Popper.js'
   *      [offset docs](https://popper.js.org/popper-documentation.html)
   * @param {Object} options.popperOptions={} - Popper options, will be passed directly to popper instance. For more information refer to Popper.js'
   *      [options docs](https://popper.js.org/popper-documentation.html)
   * @param {String} options.style = ''
   *       any style you want
  * @param {HTMLElement} popoverNode - The DOM node used as reference of the popover container (it can be a jQuery element).


   * @return {Object} instance - The generated popover instance
   */
  constructor(reference, options) {
    // apply user options over default ones
    options = { ...DEFAULT_OPTIONS, ...options };

    reference.jquery && (reference = reference[0]);

    // cache reference and options
    this.reference = reference;
    this.options = options;


    // get events list
    let events =
      typeof options.trigger === 'string'
        ? options.trigger
          .split(' ')
          .filter(
            trigger => ['click', 'focus'].indexOf(trigger) !== -1
          )
        : [];


    // MAS: a11y - Popover has to open on focus
    events = [...(new Set(events))]

    // set initial state
    this._isOpen = false;
    this._isActive = true;
    this._popperOptions = {};

    // set event listeners
    this._setEventListeners(reference, events, options);

  }

  //
  // Public methods
  //

  /**
   * Reveals an element's popover. This is considered a "manual" triggering of the popover.
   * Popovers with zero-length titles are never displayed.
   * @method Popover#show
   * @memberof Popover
   */
  show = () => this._show(this.reference, this.options);

  /**
   * Hides an element’s popover. This is considered a “manual” triggering of the popover.
   * @method Popover#hide
   * @memberof Popover
   */
  hide = () => this._hide();

  /**
   * Hides and destroys an element’s popover.
   * @method Popover#dispose
   * @memberof Popover
   */
  dispose = () => this._dispose();

  /**
   * Deactivate the popover 
   * @method Popover#deactivate
   * @memberof Popover
   * @param {Boolean} mode - If true deactivate, else activate back
   */
  deactivate = (mode) => this._deactivate(mode);

  /**
   * Toggles an element’s popover. This is considered a “manual” triggering of the popover.
   * @method Popover#toggle
   * @memberof Popover
   */
  toggle = () => {
    if (!this._isActive) {
      return this;
    }

    if (this._isOpen) {
      return this.hide();
    } else {
      return this.show();
    }
  };

  /**
   * Updates the popover's Title
   * @method Popover#updateTitleContent
   * @memberof Popover
   * @param {String|HTMLElement} content - The new content to use for the title
   */
  updateTitleContent = (content) => this._updateTitleContent(content);

  //
  // Private methods
  //

  _events = [];

  /**
   * Creates a new popover node
   * @memberof Popover
   * @private
   * @param {HTMLElement} reference
   * @param {String} template
   * @param {String|HTMLElement|TitleFunction} title
   * @param {Boolean} allowHtml
   * @return {HTMLElement} popoverNode
   */
  _create(reference, template, title, content, allowHtml) {
    // create popover element
    const popoverGenerator = window.document.createElement('div');
    popoverGenerator.innerHTML = template.trim();
    const popoverNode = popoverGenerator.childNodes[0];

    // add unique ID to our popover (needed for accessibility reasons)
    popoverNode.id = `popover_${Math.random()
      .toString(36)
      .substr(2, 10)}`;

    // set initial `aria-hidden` state to `false` (it's visible!)
    popoverNode.setAttribute('aria-hidden', 'false');

    // MAS: Adjust style
    if (this.options.style) {
      Utils.addClass(popoverNode, this.options.style)
    }


    // add title to popover
    const headerContainer = popoverGenerator.querySelector(this.options.headerSelector);
    if (!Utils.isBlank(title)) {
      const headerNode = window.document.createElement(this.options.headerTag);
      Utils.addClass(headerNode, 'cb-popover-title')
      Utils.append(headerContainer, headerNode)
      this._addTitleContent(reference, title, allowHtml, headerNode);
    } else {
      Utils.remove(headerContainer)
    }

    const contentNode = popoverGenerator.querySelector(this.options.contentSelector);
    if (!Utils.isBlank(content)) {
      // add content to popover
      this._addTitleContent(reference, content, allowHtml, contentNode);
    } else {
      Utils.remove(contentNode)

      if (Utils.elemExists(headerContainer)) {
        Utils.addClass(headerContainer, 'cb-no-margin')
      }
    }

    // return the generated popover node
    return popoverNode;
  }

  /**
 * Creates a new popover node
 * @memberof Popover
 * @private
 * @param {HTMLElement} reference
 * @param {String} template
 * @param {String|HTMLElement|TitleFunction} title
 * @param {Boolean} allowHtml
 * @return {HTMLElement} popoverNode
 */
  _createPopover(popoverNode) {
    // add unique ID to our popover (needed for accessibility reasons)
    popoverNode.id = (Utils.attr(popoverNode, 'id')) ? Utils.attr(popoverNode, 'id') : Utils.uniqueID(10, 'popover_');
    popoverNode.setAttribute('aria-hidden', 'false');

    // MAS: Adjust style
    if (this.options.style) {
      Utils.addClass(popoverNode, this.options.style)
    }

    // return the generated popover node
    return popoverNode;
  }

  _addTitleContent(reference, title, allowHtml, titleNode) {
    if (title.nodeType === 1 || title.nodeType === 11) {
      // if title is a element node or document fragment, append it only if allowHtml is true
      allowHtml && titleNode.appendChild(title);
    } else if (Utils.isFunction(title)) {
      // Recursively call ourself so that the return value of the function gets handled appropriately - either
      // as a dom node, a string, or even as another function.
      this._addTitleContent(reference, title.call(reference), allowHtml, titleNode);
    } else {
      // if it's just a simple text, set textContent or innerHtml depending by `allowHtml` value
      allowHtml ? (titleNode.innerHTML = title) : (titleNode.textContent = title);
    }
  }

  _show(reference, options) {
    // check if we should proceed
    if (!this._isActive) {
      return this;
    }

    // don't show if it's already visible
    // or if it's not being showed
    if (this._isOpen && !this._isOpening) {
      return this;
    }
    this._isOpen = true;

    // if the popoverNode already exists, just show it
    if (this._popoverNode) {
      this._popoverNode.style.visibility = 'visible';
      this._popoverNode.setAttribute('aria-hidden', 'false');
      reference.setAttribute('aria-expanded', true);

      this.popperInstance.update();
      // MAS: A11Y
      this._getFocusableNodes()

      return this;
    }

    // get title
    const title = (reference.getAttribute('data-cb-title') || reference.getAttribute('title')) || options.title;
    const content = reference.getAttribute('data-cb-content') || options.content;

    let popoverNode = null

    // This is a popover we pass the popoverNode
    if (Utils.elemExists(options.popoverNode)) {
      // return this;

      popoverNode = this._createPopover(options.popoverNode);
      reference.id = (Utils.attr(reference, 'id')) ? Utils.attr(reference, 'id') : Utils.uniqueID(10, 'popover_');
    } else {
      popoverNode = this._create(
        reference,
        options.template,
        title,
        content,
        options.html
      );
    }


    // Add `aria-describedby` to our reference element for accessibility reasons
    reference.setAttribute('aria-describedby', popoverNode.id);
    reference.setAttribute('aria-controls', popoverNode.id);
    reference.setAttribute('aria-expanded', true);

    popoverNode.setAttribute('aria-labelledby', reference.id);


    // append popover to container
    const container = this._findContainer(options.container, reference);

    this._append(popoverNode, container);

    let placementOpt = reference.getAttribute('data-cb-placement') || options.placement
    const vp = Utils.viewport()

    if (vp.prefix === 'xs' || vp.prefix === 'sm') {
      placementOpt = 'top'
    }

    this._popperOptions = {
      ...options.popperOptions,
      placement: placementOpt,
    };

    this._popperOptions.modifiers = {
      ...this._popperOptions.modifiers,
      offset: {
        ...(this._popperOptions.modifiers && this._popperOptions.modifiers.offset),
        offset: options.offset,
      },
    };

    if (options.boundariesElement) {
      this._popperOptions.modifiers.preventOverflow = {
        boundariesElement: options.boundariesElement,
      };
    }

    this.popperInstance = new Popper(
      reference,
      popoverNode,
      this._popperOptions
    );

    this._popoverNode = popoverNode;


    // MAS: A11Y
    this._getFocusableNodes()

    return this;
  }

  _getFocusableNodes() {
    const nodes = this._popoverNode.querySelectorAll(Utils.FOCUSABLE_ELEMENTS)

    if (nodes.length) {
      nodes[0].focus()
    }
  }

  _hide(/*reference, options*/) {
    // don't hide if it's already hidden
    if (!this._isOpen) {
      return this;
    }


    this._isOpen = false;

    // hide popoverNode
    this._popoverNode.style.visibility = 'hidden'
    this._popoverNode.setAttribute('aria-hidden', 'true')


    // MAS: A11Y
    if (this.reference) {
      this.reference.setAttribute('aria-expanded', false)

      // Make sure focus only goes back to trigger if activeElement 
      // is in the popover scope
      let focused = document.activeElement
      if (!focused || focused == document.body) {
        focused = null;
      } else if (document.querySelector) {
        focused = document.querySelector(":focus")
      }

      if (this.reference.contains(focused) || this._popoverNode.contains(focused)) {
        this.reference.focus()
      }
    }

    return this;
  }

  _dispose() {
    // remove event listeners first to prevent any unexpected behaviour
    this._events.forEach(({ func, event }) => {
      this.reference.removeEventListener(event, func);
    });
    this._events = [];

    if (this._popoverNode) {
      this._hide();

      // destroy instance
      this.popperInstance.destroy();

      // destroy popoverNode if removeOnDestroy is not set, as popperInstance.destroy() already removes the element
      if (!this.popperInstance.options.removeOnDestroy) {
        this._popoverNode.parentNode.removeChild(this._popoverNode);
        this._popoverNode = null;
      }
    }
    return this;
  }

  _deactivate(mode) {
    this._isActive = !mode;
  }

  _findContainer(container, reference) {
    // if container is a query, get the relative element
    if (typeof container === 'string') {
      container = window.document.querySelector(container);
    } else if (container === false) {
      // if container is `false`, set it to reference parent
      container = reference.parentNode;
    }
    return container;
  }

  /**
   * Append popover to container
   * @memberof Popover
   * @private
   * @param {HTMLElement} popoverNode
   * @param {HTMLElement|String|false} container
   */
  _append(popoverNode, container) {
    container.appendChild(popoverNode);
  }

  _setEventListeners(reference, events, options) {
    const directEvents = [];
    const oppositeEvents = [];

    events.forEach(event => {
      switch (event) {
        case 'hover':
          directEvents.push('mouseenter');
          oppositeEvents.push('mouseleave');
          break;
        case 'focus':
          directEvents.push('focus');
          oppositeEvents.push('blur');
          break;
        case 'click':
          directEvents.push('click');
          oppositeEvents.push('click');
          break;
      }
    });

    // schedule show popover
    directEvents.forEach(event => {
      const func = evt => {
        if (this._isOpening === true) {
          return;
        }
        evt.usedByPopover = true;
        this._scheduleShow(reference, options.delay, options, evt);
      };
      this._events.push({ event, func });
      reference.addEventListener(event, func);
    });

    // schedule hide popover
    oppositeEvents.forEach(event => {
      const func = evt => {
        if (evt.usedByPopover === true) {
          return;
        }
        this._scheduleHide(reference, options.delay, options, evt);
      };
      this._events.push({ event, func });
      reference.addEventListener(event, func);

      if (event === 'click' && options.closeOnClickOutside) {
        document.addEventListener('mousedown', e => {
          if (!this._isOpening) {
            return;
          }
          const popper = this.popperInstance.popper;
          if (reference.contains(e.target) ||
            popper.contains(e.target)) {
            return;
          }
          func(e);
        }, true);
      }


      // MAS: A11Y
      if (options.closeOnClickOutside) {
        // Close on ESC
        document.addEventListener('keyup', e => {
          if (!this._isOpening) {
            return;
          } else if (e.keyCode === 27) {

            func(e);
          } else {
            const popper = this.popperInstance.popper;
            if (reference.contains(e.target) ||
              popper.contains(e.target)) {
              return;
            }
            func(e);
          }
        }, true);

      }
    });
  }

  _scheduleShow(reference, delay, options /*, evt */) {
    this._isOpening = true;
    // defaults to 0
    const computedDelay = (delay && delay.show) || delay || 0;
    this._showTimeout = window.setTimeout(
      () => this._show(reference, options),
      computedDelay
    );
  }

  _scheduleHide(reference, delay, options, evt) {
    this._isOpening = false;
    // defaults to 0
    const computedDelay = (delay && delay.hide) || delay || 0;
    window.clearTimeout(this._showTimeout);
    window.setTimeout(() => {
      if (this._isOpen === false) {
        return;
      }
      if (!document.body.contains(this._popoverNode)) {
        return;
      }

      // if we are hiding because of a mouseleave, we must check that the new
      // reference isn't the popover, because in this case we don't want to hide it
      if (evt.type === 'mouseleave') {
        const isSet = this._setPopoverNodeEvent(evt, reference, delay, options);

        // if we set the new event, don't hide the popover yet
        // the new event will take care to hide it if necessary
        if (isSet) {
          return;
        }
      }

      this._hide(reference, options);
    }, computedDelay);
  }

  _setPopoverNodeEvent = (evt, reference, delay, options) => {
    const relatedreference =
      evt.relatedreference || evt.toElement || evt.relatedTarget;

    const callback = evt2 => {
      const relatedreference2 =
        evt2.relatedreference || evt2.toElement || evt2.relatedTarget;

      // Remove event listener after call
      this._popoverNode.removeEventListener(evt.type, callback);

      // If the new reference is not the reference element
      if (!reference.contains(relatedreference2)) {
        // Schedule to hide popover
        this._scheduleHide(reference, options.delay, options, evt2);
      }
    };

    if (this._popoverNode.contains(relatedreference)) {
      // listen to mouseleave on the popover element to be able to hide the popover
      this._popoverNode.addEventListener(evt.type, callback);
      return true;
    }

    return false;
  };

  _updateTitleContent(title) {
    if (typeof this._popoverNode === 'undefined') {
      if (typeof this.options.title !== 'undefined') {
        this.options.title = title;
      }
      return;
    }
    const titleNode = this._popoverNode.querySelector(this.options.innerSelector);
    this._clearTitleContent(titleNode, this.options.html, this.reference.getAttribute('title') || this.options.title)
    this._addTitleContent(this.reference, title, this.options.html, titleNode);
    this.options.title = title;
    this.popperInstance.update();
  }

  _clearTitleContent(titleNode, allowHtml, lastTitle) {
    if (lastTitle.nodeType === 1 || lastTitle.nodeType === 11) {
      allowHtml && titleNode.removeChild(lastTitle);
    } else {
      allowHtml ? titleNode.innerHTML = '' : titleNode.textContent = '';
    }
  }
}