/* ========================================================================
 * Apricot's Event Timeline
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/event-timeline.scss';

// javaScript
import Utils from './CBUtils'

/**
 * Event Timeline 
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @returns {{destroy: Function}}
 * 
 */
const EventTimeline = (data = {}) => {
  const defaultData = {
    elem: null
  }

  data = { ...defaultData, ...data }

  const elem = data.elem
  if (!Utils.elemExists(elem)) return null

  const init = () => {
    elem.eventTimeline = 'cb'

    if (Utils.hasClass(elem, 'cb-event-cards-horizontal')) {
      if (Utils.browser().msie) {
        Utils.addClass(elem, 'cb-ie-fix')
      }
  
      Utils.breakpoints()
      adjustCardHeight(Utils.viewport().prefix)
      document.addEventListener('apricot_breakpointChange', function (e) {
        const data = e.data

        adjustCardHeight(data.prefix)
      })
    }

    a11y()
  }

  const a11y = () => {
    const items = elem.querySelectorAll('ol>li')
    items.forEach((item) => {

      const date = item.querySelector('.cb-event-date')
      let dateId = ''
      if (date) {
        dateId = Utils.attr(date, 'id') ? Utils.attr(date, 'id') : Utils.uniqueID(5, 'apricot_')
        Utils.attr(date, 'id', dateId)
      }

      const title = item.querySelector('.cb-event-title')

      let titleId = ''
      if (title) {
        titleId = Utils.attr(title, 'id') ? Utils.attr(title, 'id') : Utils.uniqueID(5, 'apricot_')
        Utils.attr(title, 'id', titleId)
      }

      const links = item.querySelectorAll('.cb-meta-tags a')

      links.forEach((mlink) => {
        Utils.attr(mlink, 'aria-describedby', `${dateId} ${titleId}`)
      })
    })
  }

  const adjustCardHeight = (prefix) => {
    const items = elem.querySelectorAll('.cb-event-date')
    items.forEach((item) => {
      item.style.height = 'auto'
    })

    if (prefix !== 'xs') {
      const setting = tallestElm(items)
      const height = `${setting.max}px`
      items.forEach((item) => {
        item.style.height = height
      })
    }
  }



  const tallestElm = (arr) => {
    let result = {}
    let heightArr = [];

    result.max = 0;
    result.arr = [];

    if (arr.length > 0) {
      arr.forEach(function (elem) {
        let obj = {}
        obj.height = Utils.height(elem)
        obj.elem = elem

        heightArr.push(obj);
      })

      heightArr.sort((a, b) => {
        return a.height - b.height;
      })

      result.max = heightArr[heightArr.length - 1].height
      result.arr = heightArr
    }

    return result;
  }

  const destroy = () => {
    if (elem.eventTimeline === 'cb') {
      elem.eventTimeline = null

      elem.querySelectorAll('.cb-event-date-info').forEach((item) => {
        item.style.height = ''
      })
    }
  }

  if (elem.eventTimeline !== 'cb') {
    init();
  }

  return {
    destroy: destroy
  }
}

export default EventTimeline